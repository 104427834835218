import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/coqui-ai.github.io/coqui-ai.github.io/src/templates/NewsletterTemplate.tsx";
import { graphql } from 'gatsby';
export const pageQuery = graphql`
  query($fileAbsolutePath: String) {
    ...SidebarPageFragment
  }
`;
export const _frontmatter = {};
const layoutProps = {
  pageQuery,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "934px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "27.599999999999998%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAYAAADDl76dAAAACXBIWXMAAA3XAAAN1wFCKJt4AAABCElEQVQY042RQUsCcRDF/VB9g+59gk6eOhTRpaBbh5CgYCuiTkYGah6CSIwiyiRbiiyVFg+5EoS6LO1/XS0oXffXriC1atCDOcyDeW/mTQAXjuMwhFHcH/g9H/CaPqG/W5T0V1ShUTbqjDIb7L/abR/vE8y8KEjyMfv5NHuFS87kO0LhCBvxBOuxBPHTc0K7EVJZGV2YhI+SZAvF3qzd7f4I9h287WpNgfho8WzWWdjaZnFzh+DSMtMra0zMzTO7KjE2GewJj0/NcOKKe+jY9vCGWsvkvlom55ZqamhvBqnrG26fFDIPeYTVRIoecHiR5ir3iKJWMBqW/+TBbDynz46by/9/4sv1G2shujMHTg19AAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "IMAGE",
          "title": "IMAGE",
          "src": "/static/c9103bc33c8add5ab1fa4fa1c49c90ef/ca463/logo-wordmark.png",
          "srcSet": ["/static/c9103bc33c8add5ab1fa4fa1c49c90ef/43fa5/logo-wordmark.png 250w", "/static/c9103bc33c8add5ab1fa4fa1c49c90ef/c6e3d/logo-wordmark.png 500w", "/static/c9103bc33c8add5ab1fa4fa1c49c90ef/ca463/logo-wordmark.png 934w"],
          "sizes": "(max-width: 934px) 100vw, 934px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <h3 {...{
      "id": "welcome",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#welcome",
        "aria-label": "welcome permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "width": "16",
          "height": "16",
          "focusable": "false",
          "viewBox": "0 0 16 16"
        }}>{`
  `}<path parentName="svg" {...{
            "fill": "currentColor",
            "d": "M4.441 7.38l.095.083.939.939-.708.707-.939-.939-2 2-.132.142a2.829 2.829 0 003.99 3.99l.142-.132 2-2-.939-.939.707-.708.94.94a1 1 0 01.083 1.32l-.083.094-2 2A3.828 3.828 0 01.972 9.621l.15-.158 2-2A1 1 0 014.34 7.31l.101.07zm7.413-3.234a.5.5 0 01.057.638l-.057.07-7 7a.5.5 0 01-.765-.638l.057-.07 7-7a.5.5 0 01.708 0zm3.023-3.025a3.829 3.829 0 01.15 5.257l-.15.158-2 2a1 1 0 01-1.32.083l-.094-.083-.94-.94.708-.707.939.94 2-2 .132-.142a2.829 2.829 0 00-3.99-3.99l-.142.131-2 2 .939.939-.707.708-.94-.94a1 1 0 01-.082-1.32l.083-.094 2-2a3.828 3.828 0 015.414 0z"
          }}></path>
        </svg></a>{`Welcome`}</h3>
    <p>{`By `}<a parentName="p" {...{
        "href": "https://github.com/kdavis-coqui"
      }}>{`Kelly Davis`}</a></p>
    <p>{`🐸TTS was on 🔥 this month bringing lots of improvements and updates your way. First, we’ve
completely re-written the `}<a parentName="p" {...{
        "href": "https://tts.readthedocs.io"
      }}>{`TTS documentation`}</a>{`. Now it’s easier
than ever to start your TTS journey with the trusted guide of our new 🐸TTS documentation
by your side. Next, we introduced a new `}<a parentName="p" {...{
        "href": "https://tts.readthedocs.io/en/latest/main_classes/trainer_api.html"
      }}>{`Trainer API`}</a>{`
which is a lightweight, extensible, and feature-complete training framework for `}<em parentName="p">{`all`}</em>{` the
🐸TTS models. If that wasn’t enough, we introduced a new char-to-phoneme model
`}<a parentName="p" {...{
        "href": "https://github.com/rhasspy/gruut"
      }}>{`Gruut`}</a>{` that makes 🐸TTS even more realistic.`}</p>
    <p>{`🐸STT wasn’t sleeping either. Over the last month work began on a complete rewrite of
🐸STT’s “native client”, a native library written in C++ focused solely on inference.
In this rewrite we wanted to retain the performance, simplicity, ease-of-use, and
availability of the current “native client”, but set a path towards a new, unified
`}<a parentName="p" {...{
        "href": "https://onnx.ai"
      }}>{`ONNX`}</a>{` based “native client” that works for both 🐸STT `}<em parentName="p">{`and`}</em>{` 🐸TTS.
Though it’s early days for the new “native client”, we’ve released an
`}<a parentName="p" {...{
        "href": "https://github.com/coqui-ai/inference-engine"
      }}>{`initial version`}</a>{` so you can kick the
tires, drive it around the block, and give us some feedback.`}</p>
    <p>{`Last but not least, Coqui gave a talk at
`}<a parentName="p" {...{
        "href": "https://l3-ai.dev/"
      }}>{`L3-AI: the conference for building next-level AI assistants`}</a>{`.
The slides are available `}<a parentName="p" {...{
        "href": "https://docs.google.com/presentation/d/e/2PACX-1vQXtFe__a6P-r3lanv2CpZ0NzQzHDu_1E8uUhTaidnT-WtuPHPkKpiZsgc0gY4PmAZQ5d5CMw9fXAf9/pub?start=false&loop=false&delayms=3000"
      }}>{`here`}</a>{`;
the video + audio will be available soon.`}</p>
    <p>{`Enjoy the newsletter!`}</p>
    <h3 {...{
      "id": "tts-v010-is-out",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#tts-v010-is-out",
        "aria-label": "tts v010 is out permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "width": "16",
          "height": "16",
          "focusable": "false",
          "viewBox": "0 0 16 16"
        }}>{`
  `}<path parentName="svg" {...{
            "fill": "currentColor",
            "d": "M4.441 7.38l.095.083.939.939-.708.707-.939-.939-2 2-.132.142a2.829 2.829 0 003.99 3.99l.142-.132 2-2-.939-.939.707-.708.94.94a1 1 0 01.083 1.32l-.083.094-2 2A3.828 3.828 0 01.972 9.621l.15-.158 2-2A1 1 0 014.34 7.31l.101.07zm7.413-3.234a.5.5 0 01.057.638l-.057.07-7 7a.5.5 0 01-.765-.638l.057-.07 7-7a.5.5 0 01.708 0zm3.023-3.025a3.829 3.829 0 01.15 5.257l-.15.158-2 2a1 1 0 01-1.32.083l-.094-.083-.94-.94.708-.707.939.94 2-2 .132-.142a2.829 2.829 0 00-3.99-3.99l-.142.131-2 2 .939.939-.707.708-.94-.94a1 1 0 01-.082-1.32l.083-.094 2-2a3.828 3.828 0 015.414 0z"
          }}></path>
        </svg></a>{`🐸TTS v0.1.0 is out`}</h3>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1000px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "72%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDAAMCAgMCAgMDAwMEAwMEBQgFBQQEBQoHBwYIDAoMDAsKCwsNDhIQDQ4RDgsLEBYQERMUFRUVDA8XGBYUGBIUFRT/2wBDAQMEBAUEBQkFBQkUDQsNFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBT/wgARCAAOABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAQGAQP/xAAWAQEBAQAAAAAAAAAAAAAAAAAGBAX/2gAMAwEAAhADEAAAAX1ucmRTVxhLqf/EABkQAAMBAQEAAAAAAAAAAAAAAAMEBQIBIv/aAAgBAQABBQLPOMGVXC1o/glXpVk5Vgm7z9Pax//EABwRAAICAgMAAAAAAAAAAAAAAAECACESMSJR8P/aAAgBAwEBPwFODqrDHdDXqhJ7n//EAB4RAQABAwUBAAAAAAAAAAAAAAECAAMREiFBYbHh/9oACAECAQE/AWI2pysuQxvz37RqT61//8QAIhAAAgEEAgEFAAAAAAAAAAAAAQIDABESIQQxIiNBUWJx/9oACAEBAAY/ArxZABdtPpVH52aCxWSaLd3Hi9/kURLxuRl9DkKl5gl3Enpi3RrBjksyBDlWAQde1f/EAB4QAQEAAgICAwAAAAAAAAAAAAERACExUWFxkaHB/9oACAEBAAE/IdgmzaraPZ9GQJACHkHZe8kR+FPwn7vESWgGoyvq4dQxwLZb85JjBy1n/9oADAMBAAIAAwAAABC4/wD/xAAaEQEAAgMBAAAAAAAAAAAAAAABETEAUWGB/9oACAEDAQE/EGhqpBZIXvG3wTDn/8QAGxEBAAICAwAAAAAAAAAAAAAAAREhAIExcfD/2gAIAQIBAT8Qt8xKbAwvkjpW1ETxtz//xAAdEAEBAAMBAAMBAAAAAAAAAAABEQAhMUFhcYGR/9oACAEBAAE/EOPe1WRKjODEa3JPcYFeVAEOhBKMQQrykpBca06APcRkiFCLNGH5b9YR6aKQA4EP8+cWkFIu2xdl3L+5/9k=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "IMAGE",
          "title": "IMAGE",
          "src": "/static/1e77dc6fce06f5a577ab0e4d0674e779/dbdff/tts-v0.1.0-release.jpg",
          "srcSet": ["/static/1e77dc6fce06f5a577ab0e4d0674e779/0988f/tts-v0.1.0-release.jpg 250w", "/static/1e77dc6fce06f5a577ab0e4d0674e779/d1f95/tts-v0.1.0-release.jpg 500w", "/static/1e77dc6fce06f5a577ab0e4d0674e779/dbdff/tts-v0.1.0-release.jpg 1000w"],
          "sizes": "(max-width: 1000px) 100vw, 1000px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`By `}<a parentName="p" {...{
        "href": "https://github.com/erogol"
      }}>{`Eren Gölge`}</a></p>
    <p>{`A ton of updates and improvements are in the new v0.1.0 version of 🐸TTS. Below we cover some of
the important ones, and you can find more info in our `}<a parentName="p" {...{
        "href": "https://github.com/coqui-ai/TTS/releases/tag/v0.1.0"
      }}>{`release notes`}</a>{`.`}</p>
    <h4 {...{
      "id": "-tts-documentation",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#-tts-documentation",
        "aria-label": " tts documentation permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "width": "16",
          "height": "16",
          "focusable": "false",
          "viewBox": "0 0 16 16"
        }}>{`
  `}<path parentName="svg" {...{
            "fill": "currentColor",
            "d": "M4.441 7.38l.095.083.939.939-.708.707-.939-.939-2 2-.132.142a2.829 2.829 0 003.99 3.99l.142-.132 2-2-.939-.939.707-.708.94.94a1 1 0 01.083 1.32l-.083.094-2 2A3.828 3.828 0 01.972 9.621l.15-.158 2-2A1 1 0 014.34 7.31l.101.07zm7.413-3.234a.5.5 0 01.057.638l-.057.07-7 7a.5.5 0 01-.765-.638l.057-.07 7-7a.5.5 0 01.708 0zm3.023-3.025a3.829 3.829 0 01.15 5.257l-.15.158-2 2a1 1 0 01-1.32.083l-.094-.083-.94-.94.708-.707.939.94 2-2 .132-.142a2.829 2.829 0 00-3.99-3.99l-.142.131-2 2 .939.939-.707.708-.94-.94a1 1 0 01-.082-1.32l.083-.094 2-2a3.828 3.828 0 015.414 0z"
          }}></path>
        </svg></a>{`📝 TTS documentation`}</h4>
    <p>{`We’ve created shinny, new `}<a parentName="p" {...{
        "href": "https://tts.readthedocs.io"
      }}>{`TTS documentation`}</a>{` where you can find all
the information you need to train or test your models, implement new models, load new datasets,
and much more.`}</p>
    <p>{`The new documentation also contains `}<a parentName="p" {...{
        "href": "https://tts.readthedocs.io/en/latest/tutorial_for_nervous_beginners.html"
      }}>{`here`}</a>{`
a new, beginner-friendly intro to 🐸TTS. Getting started is easier than ever.`}</p>
    <p>{`If you see something is missing, let us know! We’re dying for feedback.`}</p>
    <h4 {...{
      "id": "-trainer-api",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#-trainer-api",
        "aria-label": " trainer api permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "width": "16",
          "height": "16",
          "focusable": "false",
          "viewBox": "0 0 16 16"
        }}>{`
  `}<path parentName="svg" {...{
            "fill": "currentColor",
            "d": "M4.441 7.38l.095.083.939.939-.708.707-.939-.939-2 2-.132.142a2.829 2.829 0 003.99 3.99l.142-.132 2-2-.939-.939.707-.708.94.94a1 1 0 01.083 1.32l-.083.094-2 2A3.828 3.828 0 01.972 9.621l.15-.158 2-2A1 1 0 014.34 7.31l.101.07zm7.413-3.234a.5.5 0 01.057.638l-.057.07-7 7a.5.5 0 01-.765-.638l.057-.07 7-7a.5.5 0 01.708 0zm3.023-3.025a3.829 3.829 0 01.15 5.257l-.15.158-2 2a1 1 0 01-1.32.083l-.094-.083-.94-.94.708-.707.939.94 2-2 .132-.142a2.829 2.829 0 00-3.99-3.99l-.142.131-2 2 .939.939-.707.708-.94-.94a1 1 0 01-.082-1.32l.083-.094 2-2a3.828 3.828 0 015.414 0z"
          }}></path>
        </svg></a>{`🚀 Trainer API`}</h4>
    <p>{`We’ve also introduced a new `}<a parentName="p" {...{
        "href": "https://tts.readthedocs.io/en/latest/main_classes/trainer_api.html"
      }}>{`Trainer API`}</a>{`.
It provides a lightweight, extensible, and feature-complete training framework for all the 🐸TTS
models. It supports mixed precision and multi GPU training right out-of-the-box and requires no
code changes in your model implementation to take advantage of these functionalities.`}</p>
    <p>{`With this new API, you can either keep your old way of training models on the terminal or use pure
Python to initialize your model and call the trainer. Using only 🐍Python allows you to run an
experiment on a Jupyter Notebook or customize as you like.`}</p>
    <h4 {...{
      "id": "️-gruut-based-char-to-phoneme",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#%EF%B8%8F-gruut-based-char-to-phoneme",
        "aria-label": "️ gruut based char to phoneme permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "width": "16",
          "height": "16",
          "focusable": "false",
          "viewBox": "0 0 16 16"
        }}>{`
  `}<path parentName="svg" {...{
            "fill": "currentColor",
            "d": "M4.441 7.38l.095.083.939.939-.708.707-.939-.939-2 2-.132.142a2.829 2.829 0 003.99 3.99l.142-.132 2-2-.939-.939.707-.708.94.94a1 1 0 01.083 1.32l-.083.094-2 2A3.828 3.828 0 01.972 9.621l.15-.158 2-2A1 1 0 014.34 7.31l.101.07zm7.413-3.234a.5.5 0 01.057.638l-.057.07-7 7a.5.5 0 01-.765-.638l.057-.07 7-7a.5.5 0 01.708 0zm3.023-3.025a3.829 3.829 0 01.15 5.257l-.15.158-2 2a1 1 0 01-1.32.083l-.094-.083-.94-.94.708-.707.939.94 2-2 .132-.142a2.829 2.829 0 00-3.99-3.99l-.142.131-2 2 .939.939-.707.708-.94-.94a1 1 0 01-.082-1.32l.083-.094 2-2a3.828 3.828 0 015.414 0z"
          }}></path>
        </svg></a>{`🗣️ Gruut based Char-to-Phoneme`}</h4>
    <p>{`v0.1.0 also comes with a new char-to-phoneme interface, based on `}<a parentName="p" {...{
        "href": "https://github.com/rhasspy/gruut"
      }}>{`Gruut`}</a>{`,
that covers most of the European languages and has a very flexible API.`}</p>
    <p>{`Gruut currently supports the following languages, and the list is growing constantly:`}</p>
    <ul>
      <li parentName="ul">{`Czech (cs or cs-cz)`}</li>
      <li parentName="ul">{`German (de or de-de)`}</li>
      <li parentName="ul">{`English (en or en-us)`}</li>
      <li parentName="ul">{`Spanish (es or es-es)`}</li>
      <li parentName="ul">{`Farsi/Persian (fa)`}</li>
      <li parentName="ul">{`French (fr or fr-fr)`}</li>
      <li parentName="ul">{`Italian (it or it-it)`}</li>
      <li parentName="ul">{`Dutch (nl)`}</li>
      <li parentName="ul">{`Russian (ru or ru-ru)`}</li>
      <li parentName="ul">{`Swedish (sv or sv-se)`}</li>
    </ul>
    <p>{`We also support Japanese and Chinese through `}<inlineCode parentName="p">{`pypinyin`}</inlineCode>{` and `}<inlineCode parentName="p">{`MeCab`}</inlineCode>{`.`}</p>
    <p>{`If you need to target a language that is not listed above, let us know and we can work together to
make it available under 🐸TTS.`}</p>
    <p>{`👏 Big thanks to `}<a parentName="p" {...{
        "href": "https://github.com/synesthesiam"
      }}>{`@synesthesiam`}</a>{` for his library and efforts in
bringing it to 🐸TTS.`}</p>
    <h3 {...{
      "id": "-monthly-tts-papers",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#-monthly-tts-papers",
        "aria-label": " monthly tts papers permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "width": "16",
          "height": "16",
          "focusable": "false",
          "viewBox": "0 0 16 16"
        }}>{`
  `}<path parentName="svg" {...{
            "fill": "currentColor",
            "d": "M4.441 7.38l.095.083.939.939-.708.707-.939-.939-2 2-.132.142a2.829 2.829 0 003.99 3.99l.142-.132 2-2-.939-.939.707-.708.94.94a1 1 0 01.083 1.32l-.083.094-2 2A3.828 3.828 0 01.972 9.621l.15-.158 2-2A1 1 0 014.34 7.31l.101.07zm7.413-3.234a.5.5 0 01.057.638l-.057.07-7 7a.5.5 0 01-.765-.638l.057-.07 7-7a.5.5 0 01.708 0zm3.023-3.025a3.829 3.829 0 01.15 5.257l-.15.158-2 2a1 1 0 01-1.32.083l-.094-.083-.94-.94.708-.707.939.94 2-2 .132-.142a2.829 2.829 0 00-3.99-3.99l-.142.131-2 2 .939.939-.707.708-.94-.94a1 1 0 01-.082-1.32l.083-.094 2-2a3.828 3.828 0 015.414 0z"
          }}></path>
        </svg></a>{`🔬 Monthly TTS Papers`}</h3>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1000px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "66.8%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDAAMCAgMCAgMDAwMEAwMEBQgFBQQEBQoHBwYIDAoMDAsKCwsNDhIQDQ4RDgsLEBYQERMUFRUVDA8XGBYUGBIUFRT/2wBDAQMEBAUEBQkFBQkUDQsNFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBT/wgARCAANABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAUGA//EABUBAQEAAAAAAAAAAAAAAAAAAAAB/9oADAMBAAIQAxAAAAG8fTjqGJiV/8QAGhAAAgMBAQAAAAAAAAAAAAAAAAECAxEhIv/aAAgBAQABBQKjsYYnqRTLzWzT/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAGBAAAgMAAAAAAAAAAAAAAAAAABABETH/2gAIAQEABj8CKULD/8QAHRAAAgIBBQAAAAAAAAAAAAAAAREAITEQUXGhsf/aAAgBAQABPyHIa9jhO4KABXMVUMCFR30P/9oADAMBAAIAAwAAABCMz//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8QP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8QP//EAB0QAQEAAgMAAwAAAAAAAAAAAAERACExQWFRgfD/2gAIAQEAAT8QE79Ycp9ayZ+Irb85V2jkm+5fmpmEui4RejKd/pkoKGl5c//Z')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "IMAGE",
          "title": "IMAGE",
          "src": "/static/ee7ae83975f5a8e36a44fa9649c1ad37/dbdff/monthly-papers.jpg",
          "srcSet": ["/static/ee7ae83975f5a8e36a44fa9649c1ad37/0988f/monthly-papers.jpg 250w", "/static/ee7ae83975f5a8e36a44fa9649c1ad37/d1f95/monthly-papers.jpg 500w", "/static/ee7ae83975f5a8e36a44fa9649c1ad37/dbdff/monthly-papers.jpg 1000w"],
          "sizes": "(max-width: 1000px) 100vw, 1000px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`By `}<a parentName="p" {...{
        "href": "https://github.com/erogol"
      }}>{`Eren Gölge`}</a></p>
    <p>{`This month we’ve also read some really interesting TTS papers. A few, which we found to be of
particular interest, are:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://arxiv.org/abs/2106.15561"
        }}>{`A Survey on Neural Speech Synthesis`}</a>{` - Not only because
they cited 🐸TTS and our latest paper but they provide a very comprehensive survey of
models. Especially useful for people just starting to work in the TTS field.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://arxiv.org/abs/2106.07889"
        }}>{`UnivNet: A Neural Vocoder with Multi-Resolution Spectrogram Discriminators for High-Fidelity
Waveform Generation`}</a>{` - A new GAN based vocoder. As we’re
quick on the draw, it is already implemented in 🐸TTS!`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://arxiv.org/abs/2106.09660"
        }}>{`WaveGrad2`}</a>{` - WaveGrad2 proposes an end-to-end TTS model
built on diffusion probabilistic models. You guessed it, we also have a WaveGrad vocoder
implementation in 🐸TTS.`}</li>
    </ul>
    <p>{`👀 See our `}<a parentName="p" {...{
        "href": "https://github.com/coqui-ai/TTS-papers"
      }}>{`TTS-papers`}</a>{` list for even more TTS papers!`}</p>
    <h3 {...{
      "id": "coqui-inference-engine-a-sneak-peek",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#coqui-inference-engine-a-sneak-peek",
        "aria-label": "coqui inference engine a sneak peek permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "width": "16",
          "height": "16",
          "focusable": "false",
          "viewBox": "0 0 16 16"
        }}>{`
  `}<path parentName="svg" {...{
            "fill": "currentColor",
            "d": "M4.441 7.38l.095.083.939.939-.708.707-.939-.939-2 2-.132.142a2.829 2.829 0 003.99 3.99l.142-.132 2-2-.939-.939.707-.708.94.94a1 1 0 01.083 1.32l-.083.094-2 2A3.828 3.828 0 01.972 9.621l.15-.158 2-2A1 1 0 014.34 7.31l.101.07zm7.413-3.234a.5.5 0 01.057.638l-.057.07-7 7a.5.5 0 01-.765-.638l.057-.07 7-7a.5.5 0 01.708 0zm3.023-3.025a3.829 3.829 0 01.15 5.257l-.15.158-2 2a1 1 0 01-1.32.083l-.094-.083-.94-.94.708-.707.939.94 2-2 .132-.142a2.829 2.829 0 00-3.99-3.99l-.142.131-2 2 .939.939-.707.708-.94-.94a1 1 0 01-.082-1.32l.083-.094 2-2a3.828 3.828 0 015.414 0z"
          }}></path>
        </svg></a>{`Coqui Inference Engine: A Sneak Peek`}</h3>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1000px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "66.8%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDAAMCAgMCAgMDAwMEAwMEBQgFBQQEBQoHBwYIDAoMDAsKCwsNDhIQDQ4RDgsLEBYQERMUFRUVDA8XGBYUGBIUFRT/2wBDAQMEBAUEBQkFBQkUDQsNFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBT/wgARCAANABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAQDBQf/xAAWAQEBAQAAAAAAAAAAAAAAAAABBAX/2gAMAwEAAhADEAAAAclbmYzpaYfF/8QAGRAAAwEBAQAAAAAAAAAAAAAAAAECBAMR/9oACAEBAAEFAueGWPBKHkXvOx2Nn//EABYRAAMAAAAAAAAAAAAAAAAAAAABEf/aAAgBAwEBPwGsrP/EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABcQAAMBAAAAAAAAAAAAAAAAAAAQMQH/2gAIAQEABj8CcxQ//8QAGBABAQEBAQAAAAAAAAAAAAAAAQAxESH/2gAIAQEAAT8hkEjxwh+YXri//9oADAMBAAIAAwAAABCA7//EABURAQEAAAAAAAAAAAAAAAAAAABh/9oACAEDAQE/EKqP/8QAFxEBAQEBAAAAAAAAAAAAAAAAAQARIf/aAAgBAgEBPxBXbt//xAAbEAEAAgIDAAAAAAAAAAAAAAABABEhkTFhgf/aAAgBAQABPxBk41MRrUeEApk6i0L+Q27ZP//Z')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "IMAGE",
          "title": "IMAGE",
          "src": "/static/e14c1f8fc43a14aef0ffcea2ec716c96/dbdff/inference-engine-sneak-peek.jpg",
          "srcSet": ["/static/e14c1f8fc43a14aef0ffcea2ec716c96/0988f/inference-engine-sneak-peek.jpg 250w", "/static/e14c1f8fc43a14aef0ffcea2ec716c96/d1f95/inference-engine-sneak-peek.jpg 500w", "/static/e14c1f8fc43a14aef0ffcea2ec716c96/dbdff/inference-engine-sneak-peek.jpg 1000w"],
          "sizes": "(max-width: 1000px) 100vw, 1000px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`By `}<a parentName="p" {...{
        "href": "https://github.com/reuben"
      }}>{`Reuben Morais`}</a></p>
    <p>{`From the very inception of the 🐸STT project, one of our goals was to build a speech-to-text system
that is easy to integrate and deploy into any product, regardless of the platform or programming
language of choice. 🐸STT was naturally divided between two main components: the training
infrastructure and a lean deployment library meant to be integrated into speech-enabled products.
The training code is built largely in Python using TensorFlow and other ML libraries. The deployment
library, which in STT is called the “native client”, is a native library written in C++, focused
solely on inference. It exposes a C API which is then bound to various programming languages.
This architecture enabled us to make STT available universally, directly from your favorite package
manager.`}</p>
    <p>{`Since then, we’ve learned a lot about how people use STT, and the technologies used to build machine
learning pipelines have also evolved significantly. One disadvantage of the current architecture
is how fragile the connection between the training infrastructure and the deployment library is:
we’ve managed to bend the TensorFlow tooling to our will in order to implement efficient model
exports, sometimes leveraging obscure functionality which can be undocumented, unstable, and
sometimes entirely deprecated. This meant that sometimes changing the model architecture would
unearth obscure problems, which required deep knowledge of both STT and TensorFlow internals to fix.`}</p>
    <p>{`Given these learnings, we at Coqui have been thinking about the future direction of 🐸STT and its
deployment library. We want to keep the performance, simplicity, ease-of-use, and availability of
our libraries, while finding solutions for the problems described above. We also want to draw a
path towards a unified deployment tool that works for both 🐸STT and 🐸TTS, the latter being built
with PyTorch, not TensorFlow. This foundation could then be expanded to work with any speech model.`}</p>
    <p>{`With all this in mind, we’ve started working on the next generation of our “native client”, the
Coqui Inference Engine. Built as an independent project, rather than as an STT submodule, and based
on `}<a parentName="p" {...{
        "href": "https://onnx.ai"
      }}>{`ONNX`}</a>{`, a standard interchange format for machine learning models, the Coqui
Inference Engine will be a unified solution for running speech models efficiently. We’re starting
with 🐸STT and 🐸TTS, and in the future will support a variety of architectures, reducing the gap
between speech model architecture exploration and efficient deployment.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1000px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "75.6%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAYAAADkmO9VAAAACXBIWXMAAFxGAABcRgEUlENBAAACfUlEQVQ4y3VTTXPaMBTk7/faQ3vrTA9tD+k0ZRLSEiBAJkAgBBtj4y8Z+QNbkk2nH39gK8kJoSQ97FhjP+3b93bd4JxB8BKbLMTYv8IyNMAZx5yM0LNbsIklvwvkeY44TpBlGRhjGpxzjcNzo2AFSlFhTZf4Mn+HG68vCzhu3As0rQ9YBHeaMAgDzGZ3sB0HRVE8I3o8N3Q3qVKR7qofyLMcRVbgZ/kHPCvxq/oNtuXI0kwTKdQK+V7p4bnx1KGAEAIRjdCRow79S1xZbXStb7iy2/BjD4IJXavqdpVAVSqUKOVTQ75vPEpVKlUhoQRdp4W2dYa+10ZPEUpil6z1brfbLWicwlxv4IYJfEIRkBhkE+sd7xXuIS/txA4s5yhZCVEIxFEC07Tq3clJ7q0Ar94P8fZkjHenI7w5ucXrj9doD+dPhI/7UWcaU6RpqlWrCajsniaJHLPSCpXTG5qBSoRSYbhJ4PgbkIjWI6v5SRRpFw3DxFQ+b6cz2LYti0OsiYOVR+C4oW4kRAk7NTF2B5hZSwxuHXhhJBuWh6YwnTWlUl2q88YxWd3gbNLEp9YQrc4I7MFlZdKarhBQKtXJ/SVZHZvD/QlRO6guxHEslZeyiVxFXjw03OqRFdSuVdSUy1VVO7zP4XE4q7KSJFKFF8D3A4QhQUxjqY79Y+BxDp8pVFDdVUQ86sL2V1hYBlzflbGIdKSSNMGzZBygcaxOOdxeNvHd/YyO+xUdp4m+f47L1Sm65hk84u7X8uK/fPwhkwrnto/e1Mb1vYuxscZgbqM7XWJiuEiS9EWil0eWLxP5L08WPi76C3RGK7QGBk67Bs77JnoT58FN9t+R/wLtYzfPW0rAaAAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "IMAGE",
          "title": "IMAGE",
          "src": "/static/5c1039aa2349c2efef02a024489ec724/da8b6/inference-engine-sneak-peek-diagram.png",
          "srcSet": ["/static/5c1039aa2349c2efef02a024489ec724/43fa5/inference-engine-sneak-peek-diagram.png 250w", "/static/5c1039aa2349c2efef02a024489ec724/c6e3d/inference-engine-sneak-peek-diagram.png 500w", "/static/5c1039aa2349c2efef02a024489ec724/da8b6/inference-engine-sneak-peek-diagram.png 1000w"],
          "sizes": "(max-width: 1000px) 100vw, 1000px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`In the diagram above you can see an overview of how the components connect and how models flow.
First-party components (meaning usually maintained by Coqui) are colored green, third-party
components (community maintained) are colored in blue, and cases where both Coqui and the
community would maintain offerings are colored in both blue and green.`}</p>
    <p>{`These are the early days for the Coqui Inference Engine, and we want to invite all interested
developers to collaborate on its design and implementation. Check out
`}<a parentName="p" {...{
        "href": "https://github.com/coqui-ai/inference-engine"
      }}>{`the repository`}</a>{` for more information and
`}<a parentName="p" {...{
        "href": "https://gitter.im/coqui-ai/inference-engine"
      }}>{`join the discussion on Gitter`}</a>{`.`}</p>
    <h3 {...{
      "id": "coqui-appearance-at-l3-ai",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#coqui-appearance-at-l3-ai",
        "aria-label": "coqui appearance at l3 ai permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "width": "16",
          "height": "16",
          "focusable": "false",
          "viewBox": "0 0 16 16"
        }}>{`
  `}<path parentName="svg" {...{
            "fill": "currentColor",
            "d": "M4.441 7.38l.095.083.939.939-.708.707-.939-.939-2 2-.132.142a2.829 2.829 0 003.99 3.99l.142-.132 2-2-.939-.939.707-.708.94.94a1 1 0 01.083 1.32l-.083.094-2 2A3.828 3.828 0 01.972 9.621l.15-.158 2-2A1 1 0 014.34 7.31l.101.07zm7.413-3.234a.5.5 0 01.057.638l-.057.07-7 7a.5.5 0 01-.765-.638l.057-.07 7-7a.5.5 0 01.708 0zm3.023-3.025a3.829 3.829 0 01.15 5.257l-.15.158-2 2a1 1 0 01-1.32.083l-.094-.083-.94-.94.708-.707.939.94 2-2 .132-.142a2.829 2.829 0 00-3.99-3.99l-.142.131-2 2 .939.939-.707.708-.94-.94a1 1 0 01-.082-1.32l.083-.094 2-2a3.828 3.828 0 015.414 0z"
          }}></path>
        </svg></a>{`Coqui Appearance at L3-AI`}</h3>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1000px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "50%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAAsTAAALEwEAmpwYAAABx0lEQVQoz31STU8aURSdIB/DvDcfIAqIsqggMwyjMjMgrRqjsbZG1MQNYaebduuGuMO4aRpX3bjpz+l/6O85vfdNMFaJi5M7782559yPp1lOgOVKDKccwSIIu4O88GDI9rtIOD4MEYA1SsshTDuAVq31EcVD1Le/YoVgk2jecF8JeHNMPAjZIcEuams78IPPqjAtl3exkGkgm21Cz20QgYimT+T5VelkNkNiktylOZ+ixkQWMAgZEkxnG0hl1lWcJb0U4LZMZxPSCtR5VrkwE1PtpfuGe4h2+wvC7iV8/xTNtTEK9gCLS9vKdGdwicfHX3h4+Imnp9/49n2CrN76r4tnQW49jM4Qhhf49HGEuH8BSw4g5ZYaOAtGvSGm9z8wmUxxdzfF9c2tynsrKNpqYzxLRir9QUVdtObMLeEyWEwXDE/NnNvWVIJoImc05i7hNZ6FDQ856WLVjFGxI6SMFtKZJrRCMcJ6fYxS8RjSTobO74rfo4LFMTlLy6f/HQX+ZgPbpOXoLg6ORuj1z6EVSyEO/T842fyL6so+ytUuVusDOJUINsGi+Tm1HpxqjCItZ6kcEidCYXGLKqUNU5sL9CJ2965IcIh/F/QPtKqJincAAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "IMAGE",
          "title": "IMAGE",
          "src": "/static/d76069cc5cdf496292251efa21b118ac/da8b6/l3-ai.png",
          "srcSet": ["/static/d76069cc5cdf496292251efa21b118ac/43fa5/l3-ai.png 250w", "/static/d76069cc5cdf496292251efa21b118ac/c6e3d/l3-ai.png 500w", "/static/d76069cc5cdf496292251efa21b118ac/da8b6/l3-ai.png 1000w"],
          "sizes": "(max-width: 1000px) 100vw, 1000px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`By `}<a parentName="p" {...{
        "href": "https://github.com/JRMeyer"
      }}>{`Josh Meyer`}</a></p>
    <p>{`This year Coqui appeared as a featured partner at `}<a parentName="p" {...{
        "href": "https://l3-ai.dev/"
      }}>{`L3-AI: the conference for building next-level AI assistants`}</a>{`.
Josh Meyer delivered a talk about deploying scalable, neural voice technologies at the enterprise
level. Soon the recordings will be made available to everyone who wasn’t able to attend the
conference virtually, so you can watch the talk and lively Q&A session.`}</p>
    <p>{`You can find our presentation slides `}<a parentName="p" {...{
        "href": "https://docs.google.com/presentation/d/e/2PACX-1vQXtFe__a6P-r3lanv2CpZ0NzQzHDu_1E8uUhTaidnT-WtuPHPkKpiZsgc0gY4PmAZQ5d5CMw9fXAf9/pub?start=false&loop=false&delayms=3000"
      }}>{`here`}</a>{`,
and listen to us talk about related themes with Rasa on their podcast
`}<a parentName="p" {...{
        "href": "https://podcasts.apple.com/us/podcast/open-source-speech-technology/id1533150162?i=1000518100336"
      }}>{`here`}</a>{`.
We will keep you posted on when the new L3AI recordings become available.`}</p>
    <p>{`In addition to our featured presentation, Coqui had the most active company booth at the
conference! We want to thank all of you who showed up and participated in the lively
discussions!`}</p>
    {
      /* markdownlint-enable line-length */
    }


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      